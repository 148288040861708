import React from 'react';
import logo from './WKL-logo-white-Png.png';
import abstract from './Frame 417.png';
import { Link, NavLink } from 'react-router-dom';

export default function Header() {
  return (
    <>
      <div id='preheader'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-1 desktopshow'>
             <Link to="/"> <img src={logo} className='img-fluid logowkl' alt="Logo" /></Link>
            </div>
            <div className='col-md-11 p-0'>
              <div className='row align-items-center'>
                <div className='col-md-10 col-8'>
                  <div className='marquee'>
                    <span><i className='fa fa-circle'></i>&nbsp;&nbsp;&nbsp;&nbsp; Kabaddi: The game of India. The WKL is all set to be back with it's power packed season 2, brace yourselves for what's coming?
                    </span>&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
                <div className='col-md-2 col-4 text-right'>
                <NavLink  target='_blank' to="https://www.instagram.com/wklindia/" className='mr-2'><i className='fa fa-instagram'></i></NavLink>

                  <NavLink target='_blank' to="https://www.facebook.com/profile.php?id=61567005626564" className='mr-2'><i className='fa fa-facebook-official' aria-hidden='true'></i></NavLink>
                  <NavLink  target='_blank' to="https://www.linkedin.com/company/105152735/admin/dashboard/" className='mr-2'><i className='fa fa-linkedin-square' aria-hidden='true'></i></NavLink>
                  <NavLink  target='_blank' to="https://www.youtube.com/@wklindiaoffical"><i className='fa fa-youtube-play' aria-hidden='true'></i></NavLink>
                </div>
              </div>

              <nav className='navbar navbar-expand-lg navbar-light bg-light'>
               <div className='d-flex'>
              

                <div className='logom mobileshow'>
                <Link to="/"> <img src={logo} className='img-fluid logowkl' alt="Logo" /></Link>

                </div>
                <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                  <span className='navbar-toggler-icon'></span>
                </button>
               </div>
                <div className='collapse navbar-collapse' id='navbarNav'>
                  <ul className='navbar-nav mr-auto'>
                    <li className='nav-item'>
                      <NavLink className='nav-link' to="/">Home</NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink className='nav-link' to="/about">About Us</NavLink>
                    </li>

                    <li className='nav-item'>
                      <NavLink className='nav-link' to="/news">News</NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink className='nav-link' to="/standings">Standings</NavLink>
                    </li>

                  
                    <li className='nav-item'>
                      <NavLink className='nav-link' to="/team">Teams</NavLink>
                    </li>
                   
                    <li className='nav-item'>
                      <NavLink className='nav-link' to="/video">Video</NavLink>
                    </li>
                    {/* <li className='nav-item'>
                      <a className='nav-link' href="#">Contact</a>
                    </li> */}
                  </ul>
                  <img src={abstract} className='img-fluid abstractnav' alt="Abstract" />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
