import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import breadcumb from './bread (1).jpg'
import insta from './INSTA1.png'
import insta2 from './INSTA2.png'
import insta3 from './INSTA3.png'
import { Link } from 'react-router-dom';

export default function Video() {

    const options = {
        loop: false,
        margin:20,

        nav: false,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        items: 3.2, // Number of items to display
        responsive: {
            0: {
              margin:10,
              items: 2.1, // 1 item for small screens
            },
            600: {
              items: 5, // 2 items for medium screens
            },
            1000: {
              items:5, // 3 items for large screens
            },
          },
    
    
      };
  return (
    <>

    
    <Header/>

    <div className="breadcrumb">
        <img src={breadcumb} alt="Breadcrumb Image" className="breadcrumb-image" />
        <span className="breadcrumb-text"> Videos</span>
      </div>


    <section id='reels'>

          <OwlCarousel className="owl-themevideo p-0" {...options}>
<div className='item'>
    <Link to="https://www.instagram.com/wklindia/">
    <img src={insta}  className='img-fluid'/>
    </Link>
</div>
<div className='item'>
<Link to="https://www.instagram.com/wklindia/">
    <img src={insta2}  className='img-fluid'/>
    </Link>
</div>

<div className='item'>
<Link to="https://www.instagram.com/wklindia/">
    <img src={insta3}  className='img-fluid'/>
    </Link>
</div>

        </OwlCarousel>
    </section>
    <Footer/>


    </>
  )
}
