import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner from './banner.png';
import NewCard from './NewCard';
import Highlight from './Highlight';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Standing from '../Standing';
import Banner from './Banner';
import Mbanner from './Mbanner';
import img4 from './Banner.jpg'
import Fixture from './Fixture';
import video from './Web 01.mp4'
import Video from '../Video';
import Video1 from '../Video1';
const Home = () => {
  const slides = [
    {
      id: 0,
      title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
      image: banner,
    },
    {
      id: 1,
      title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
      image: banner,
    },
    {
      id: 2,
      title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
      image: banner,
    },
    {
      id: 3,
      title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
      image: banner,
    },
  ];

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout:7000,
    autoplayHoverPause: true,
    items: 1, // Number of items to display
  };

  return (
    <>
    <Header/>
<Banner classname ="desktopshow" />
<Mbanner classname ="mobileshow"/>
{/* <Fixture/> */}

<Standing/>

{/* <Video1/> */}
{/* <video autoPlay muted loop className="VIDEOABOUT1">

<source src={video} type="video/mp4" />
<source src="movie.ogg" type="video/ogg" />
Your browser does not support the video tag.
</video> */}



<img src={img4}  className='img-fluid'/>
      {/* <NewCard /> */}
      <Highlight />
      <Footer/>
    </>
  );
};

export default Home;
