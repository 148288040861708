import React from "react";
import breadcumb from "../bread (1).jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import blogimg from "./blogimg.png";
import imgright from './Rectangle 180 (2).png'
export default function Blogdetails() {
  return (
    <>
      <Header />

      <img src={blogimg} className="img-fluid" />

      <section className="blog-desc">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="blog-date">
               <div className="highlightblog"> 
              <p>   <span className="newtab">HIGHLIGHTS</span> &nbsp;&nbsp; &nbsp;  MON 04:30 2024 | WKL TEAM</p>
               </div>
               <div className="sharebtn">
               <i class="fa fa-share-alt" aria-hidden="true"></i>

               </div>
              </div>
              <div className="blog-content">
                <h5>
                  Womens Kabaddi League Starting From Next Year In The Month Of
                  February 2025..
                </h5>

                <p>
                  We at Womens Kabaddi League aim to plan and implement
                  education policies by shaping the minds and hearts of our
                  students, thereby imparting value aided quality education,
                  implement education policies by shaping the minds and hearts
                  of our students. We at Womens Kabaddi League aim to plan and
                  they implement education policies by shaping the minds and
                  hearts of our students.
                </p>

                <p>
                  We at Womens Kabaddi League aim to plan and implement
                  education policies by shaping the minds and hearts of our
                  students, thereby imparting value aided quality education,
                  implement education policies by shaping the minds and hearts
                  of our students. We at Womens Kabaddi League aim to plan and
                  they implement education policies by shaping the minds and
                  hearts of our students.
                </p>

                <p>
                  We at Womens Kabaddi League aim to plan and implement
                  education policies by shaping the minds and hearts of our
                  students, thereby imparting value aided quality education,
                  implement education policies by shaping the minds and hearts
                  of our students. We at Womens Kabaddi League aim to plan and
                  they implement education policies by shaping the minds and
                  hearts of our students.
                </p>

                <p>
                  We at Womens Kabaddi League aim to plan and implement
                  education policies by shaping the minds and hearts of our
                  students, thereby imparting value aided quality education,
                  implement education policies by shaping the minds and hearts
                  of our students. We at Womens Kabaddi League aim to plan and
                  they implement education policies by shaping the minds and
                  hearts of our students.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="rightsidebar">

                <h4>Latest Updates</h4>
                <div className="rightcard">
                  <div className="row">
                    <div className="col-md-5 col-4">
                      <img src={imgright} className="img-fluid" />
                    </div>
                    <div className="col-md-7 col-8 align-self-center">
                      <div className="rightblog">
                        <h5>
                          Womens Kabaddi League Starting From Next Year In The
                          
                        </h5>
                        <p>Read More</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="separate"></div>
                <div className="rightcard">
                  <div className="row">
                    <div className="col-md-5 col-4">
                      <img src={imgright} className="img-fluid" />
                    </div>
                    <div className="col-md-7 col-8 align-self-center">
                      <div className="rightblog">
                        <h5>
                          Womens Kabaddi League Starting From Next Year In The
                          
                        </h5>
                        <p>Read More</p>
                      </div>
                    </div>
                  </div>
                </div>

<div className="separate"></div>


                <div className="rightcard">
                  <div className="row">
                    <div className="col-md-5 col-4">
                      <img src={imgright} className="img-fluid" />
                    </div>
                    <div className="col-md-7 col-8 align-self-center">
                      <div className="rightblog">
                        <h5>
                          Womens Kabaddi League Starting From Next Year In The
                          
                        </h5>
                        <p>Read More</p>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
