import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bg from '../imgbox/bengaluru.png'
import bg1 from '../logo/BH.png'
import breadcumb from '../bread (1).jpg'

import d1 from '../logo/DD.png'
import d11 from '../imgbox/delhi (1).png'


import g1 from '../logo/GA.png'
import g11 from '../imgbox/gujrat (2).png';


import r1 from '../logo/RR.png'
import r11 from '../imgbox/rajastan.png'


import u1 from '../logo/UPGS.png'
import u11 from '../imgbox/UP (1).png'



import t1 from '../logo/TW.png'
import t11 from '../imgbox/telugu (1).png'



import h1 from '../logo/HH.png'
import h11 from '../imgbox/haryana (1).png'


import p1 from '../logo/GM.png'
import p11 from '../imgbox/maratha (1).png'

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
export default function Team() {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
   

    // Fetching the team data from the API using fetch


  }, []);

  return (
    <>

    <Header/>

     
     
<div class="breadcrumb">
    <img src={breadcumb} alt="Breadcrumb Image" class="breadcrumb-image"/>
    <span class="breadcrumb-text"> TEAMS</span>
</div>
      <section id="teamsection">
        <div className="container-fluid">
          <div className="row">
            
                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison bang`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">Bengaluru Hawks	</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={bg1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={bg}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>



                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison delhi`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">Delhi Durgas		</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={d1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={d11}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>
          


                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison Guj`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">Gujrat Angles		</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={g1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={g11}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>






                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison Har`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">Haryana Hustlers		</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={h1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={h11}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>


                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison Punjab`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">Great Marathas			</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={p1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={p11}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>



                
                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison Rajasthan`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">Rajasthan Raiders		</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={r1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={r11}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>

                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison Tel`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">Telugu Warriors			</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={t1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={t11}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>



                <div className="col-md-4 col-12">
                 <div className="teamnewcard">
                 <div
                    className={`team-card-bengaluru-bison   UP`}
                    style={{
                      backgroundImage: ""
                        ? `url('')`
                        : "",
                    }}
                  >
                    <div class="team-card-content">
                      <div className="widthfit">
                      <h2 class="team-card-heading">UP Ganga Strikers		</h2>
                      <div className="linecard"></div>
                      </div>
                      <div class="team-card-buttons">
                      {/* <Link to={`/players?id=${team.team_name}`}> */}
                   <button class="team-card-btn team-card-matches-btn">
                          Matches
                        </button>
                        {/* </Link> */}
                        <button class="team-card-btn team-card-profile-btn">
                          View Profile
                        </button>
                      </div>
                    </div>
                    <div class="team-card-logo-corner">
                      <img
                        src={u1}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo"
                      />

                      <img
                        src={u11}
                        alt="Bengaluru Bison Logo"
                        class="team-card-logo1"
                      />
                    </div>
                  </div>
                 </div>
                </div>



                
          
          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
}
