import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import breadcumb from "./bread (1).jpg";
import img1 from "./Teamlogo/img1.jpg";
import slider1 from "./About/Banner-01 (2).png";
import slider2 from "./About/Banner-02 (1).png";

import video from './About/Women.mp4'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import element from './WKL Elements-02.png'

import logo1 from './logo/BH.png'
import logo2 from './logo/DD.png'
import logo3 from './logo/GA.png'
import logo4 from './logo/GM.png'
import logo5 from './logo/HH.png'
import logo6 from './logo/UPGS.png'
import logo7 from './logo/RR.png'
import logo8 from './logo/TW.png'









export default function About() {
  const options = {
    loop: true,
    margin: 0,
    dots:false,
    nav: false,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: true,
    items: 1, // Number of items to display
  };


  const options1 = {
    loop: true,
    margin:50,
    dots: false,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000, // Autoplay interval set to 3 seconds
    autoplayHoverPause: true,
    items: 9, // Default number of items
    responsive: {
      0: {
        items: 3, // Show 3 items on mobile
        autoplayTimeout: 3000, // Autoplay interval for mobile
      },
      768: {
        items: 8, // Show 8 items for larger screens
      }
    }
  };

  

  return (
    <>
      <Header />
      {/* <div class="breadcrumb">
    <img src={breadcumb} alt="Breadcrumb Image" class="breadcrumb-image"/>
    <span class="breadcrumb-text"> About Us</span>
</div> */}

      <section id="aboutus1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12 align-self-center">


              <div className="left-content-about">

       
              <video autoPlay muted loop className="VIDEOABOUT">

        <source src={video} type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" />
        Your browser does not support the video tag.
      </video>



                {/* <h2>WKL</h2> */}
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="right-content-about">
                <h5>Prestige and Power</h5>
                <h6> Womens Kabaddi League</h6>

                <p>
                Women Kabaddi League Limited (WKLL) is the parent company behind the globally
recognized Women Kabaddi League. As a public limited company, WKLL is dedicated to the
promotion, development, and global expansion of women kabaddi, focusing on building a
sustainable, inclusive, and professional sporting ecosystem for women athletes. The league
under WKLL's management has pioneered in providing a global platform for female kabaddi
players to showcase their talent, compete professionally, and inspire future generations.
                </p>
              </div>
            </div>
          </div>
        </div>


<div className="logo-slider">
  <div className="row">
  <OwlCarousel className="owl-theme" {...options1}>
<div className="item">
  <img src={logo1} className="img-fluid"/>
</div>

<div className="item">
  <img src={logo2} className="img-fluid"/>
</div>

<div className="item">
  <img src={logo3} className="img-fluid"/>
</div>


<div className="item">
  <img src={logo4} className="img-fluid"/>
</div>



<div className="item">
  <img src={logo5} className="img-fluid"/>
</div>


<div className="item">
  <img src={logo6} className="img-fluid"/>
</div>

<div className="item">
  <img src={logo7} className="img-fluid"/>
</div>

<div className="item">
  <img src={logo8} className="img-fluid"/>
</div>
    </OwlCarousel>
  </div>
</div>
        <div className="imgslider">
          <div className="border">
            <OwlCarousel className="owl-theme" {...options}>
              

              <div className="item">
                <img src={slider2} className="img-fluid" />
              </div>
            </OwlCarousel>
          </div>
        </div>

        <div className="quotes">
          <div className="row">
            <div className="content-quote">
              <div className="caption">
                <div className="mx-auto">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
                <h4>who we are & history</h4>
                <p>


                WKL is India’s first company to establish a global platform for women’s kabaddi, leading the way in empowering athletes and inspiring future generations. Our mission is to provide women athletes with the resources, infrastructure, and support needed to excel on the international stage. We are committed to promoting gender equality and breaking barriers in sports, while fostering grassroots talent through academies and coaching initiatives. The league continues to grow, with our first successful session held in Dubai and the second session now in Delhi 
                                </p>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <section class="core-principles">
          <div class="container-fluid">
            <div class="text-center  mb-4">
              <h5 class="mission-title">Our Core Principles</h5>
              <h2 class="section-title">Mission, Vision & Values</h2>
            </div>
            <div class="row text-center btop">
              <div class="col-md-4 border-right">
                <div className="mbox">
                  <h3 class="text-orange">Mission</h3>
                  <p>
                  To Expand the Reach of Kabaddi Globally: Lead the transformation of kabaddi from a
regional sport to a global phenomenon, increasing its viewership, fan base, and international
recognition through strategic marketing, media collaborations, and public engagement.
                  </p>
                </div>
              </div>

              <div class="col-md-4 border-right">
                <div className="mbox2">
                  <h3 class="text-orange">Vision</h3>
                  <p>
                  To become the premier global platform for women’s kabaddi, empowering women athletes
and inspiring future generations by driving the growth of kabaddi as a professional sport,
while promoting gender equality, inclusivity, and excellence on and off the field.
                  </p>
                </div>
              </div>

              <div class="col-md-4 border-right">
                <div className="mbox2">
                  <h3 class="text-orange">Values</h3>
                  <p>
                  Our core values empower women athletes by amplifying their voices and ensuring inclusivity through equal opportunities. We uphold excellence and integrity in sports while fostering community engagement to create lasting social impact and inspire a passion for sport.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* 
<section id='aboutus'>
<div className='container-fluid'>
    <div className='row'> 
        <div className='col-md-8 col-12'>
            <h4>WKL INDIA</h4>
            <p>Welcome to the official site of the Women's Kabaddi League Association, a historic drive by Women Kabbadi League Ltd. and Preparing LLC. Our association has changed the game of kabaddi, presenting imaginative components that have raised it to an optimistic level for the two players and fans. With a solid spotlight on global norms, Women Kabaddi League Ltd. and Preparing have introduced another period for kabaddi, reinvigorating the game.</p>
<p>By getting kabaddi to significant metropolitan urban communities India and locales where it was at this point to acquire a traction, we have extended its span and made it open to a more extensive crowd. In the debut release of the association, we are pleased to highlight a sum of eight groups, making the Women's Kabaddi League Association the biggest Women's games association in India concerning geological portrayal and number of gatherings.Welcome to the authority site of the Women's Kabaddi League Association, a historic drive by Women Kabaddi League Ltd. and Preparing LLC. Our association has changed the game of</p>
<p>kabaddi, presenting imaginative components that have raised it to an optimistic level for the two players and fans. With a solid spotlight on global norms, reinvigorating the game.By getting kabaddi to significant metropolitan urban communities India and locales where it was at this point to acquire a traction, we have extended its span and made it open to a more extensive crowd. In the debut release of the association, we are pleased to highlight a sum of eight groups, making the Women’s Kabaddi League Association the biggest Women’s games association in India concerning geological portrayal and number of gatherings.</p>
        </div>
        <div className='col-md-4 col-12'>
            <img src={img1} className='img-fluid'/>
        </div>
    </div>
</div>

</section> */}

      <Footer />
    </>
  );
}
