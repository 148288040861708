import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner from './BANNERnew.jpg';
import banner1 from './Web-Banner-01.png'
import banner10 from './BannerWeb.jpg'
import NewCard from './NewCard';
import Highlight from './Highlight';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Standing from '../Standing';


const Banner = () => {
  const slides = [

    // {
    //   id: 0,
    //   title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
    //   image: banner10,
    // },
    {
      id: 0,
      title: "Women’s Kabaddi League Starting From Beginning Of The Year 2025..",
      image: banner1,
    }
  ];

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout:7000,
    autoplayHoverPause: true,
    items: 1, // Number of items to display
  };

  return (
    <>
    {/* <img src={banner} className='img-fluid desktopshow' width="100%"/> */}
   <OwlCarousel className="owl-theme desktopshow" {...options}>
        {slides.map((slide) => (
          <div className="item" key={slide.id} style={{ backgroundImage: `url(${slide.image})`, height: '90vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="overlay"></div>
            <div className="content">
              <h5>{slide.title}</h5>
            </div>
          </div>
        ))}
      </OwlCarousel>   

    </>
  );
};

export default Banner;
