import React, { useState, useEffect } from "react";
import team1 from './Teamlogo/bangaluru.png'
import team2 from './Teamlogo/delhi.png'
import team3 from './Teamlogo/gujarat.png'
import team4 from './Teamlogo/haryana.png'
import team5 from './Teamlogo/maratha (2).png'
import team6 from './Teamlogo/rajasthan.png'
import team7 from './Teamlogo/telugu.png'
import team8 from './Teamlogo/up.png'


export default function Standing() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch('https://dev-ipkl.ipkl.in/api/resource/Standings?fields=["*"]');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.position - b.position);
        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <section id="standing">
        {/* <div className="headingstanding">
          <h4>MATCH STANDINGS</h4>
        </div> */}

        <div className='headingsection mx-auto'>
        <h4 className="text-white">POINTS TABLE </h4>
      </div>
        <div id="table">
          <table class="table table-striped table-bordered">
            <thead class="custom-header">
              <tr>
                <th>POS</th>
                <th>Logo</th>
                <th className="tablwidth1">Team Name</th>
                <th></th>
                <th>P</th>
                <th>W</th>
                <th>L</th>
                <th>D</th>
                <th>+/-</th>
                <th className="tablwidth">Forms</th>
                {/* <th>Pts.</th> */}
              </tr>
            </thead>
            <tbody>
              
            <tr>
  <td className="text-center">1</td>
  <td>
    <img src={team1} alt="Bengaluru Hawks" />
  </td>
  <td className="text-left">Bengaluru Hawks</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>
<tr>
  <td className="text-center">2</td>
  <td>
    <img src={team2} alt="Delhi Durgas" />
  </td>
  <td className="text-left">Delhi Durgas</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>
<tr>
  <td className="text-center">3</td>
  <td>
    <img src={team3}  alt="Gujrat Angles" />
  </td>
  <td className="text-left">Gujrat Angles</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>
<tr>
  <td className="text-center">4</td>
  <td>
    <img src={team4}  alt="Haryana Hustlers" />
  </td>
  <td className="text-left">Haryana Hustlers</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>
<tr>
  <td className="text-center">5</td>
  <td>
    <img src={team5}  alt="Great Marathas" />
  </td>
  <td className="text-left">Great Marathas</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>
<tr>
  <td className="text-center">6</td>
  <td>
    <img src={team6}  alt="Rajasthan Raiders" />
  </td>
  <td className="text-left">Rajasthan Raiders</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>
<tr>
  <td className="text-center">7</td>
  <td>
    <img src={team7}  alt="Telugu Warriors" />
  </td>
  <td className="text-left">Telugu Warriors</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>
<tr>
  <td className="text-center">8</td>
  <td>
    <img src={team8}  alt="Up Ganga Strikers" />
  </td>
  <td className="text-left">Up Ganga Strikers</td>
  <td></td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>0</td>
  <td>
    <ul className="d-flex">
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
      <li class="form-toss"><p>-</p></li>
    </ul>
  </td>
  {/* <td>0</td> */}
</tr>

              
               
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
