// Import necessary components from react-router-dom
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Blog from './Blog/Blog';
import './App.css';

// import Home from './pages/Home';
// import About from './pages/About';
// import Contact from './pages/Contact';
import Home from './Home/Home';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import StandingPage from './Standings/StandingPage';
import Team from './Team/Team';
import About from './About';
import Blogdetails from './Blog/Blogdetails';
import Video from './Video';
import Registration from './Registration';

function App() {
  return (
    <>
    {/* <Header/> */}
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<Blog />} />
        <Route path="/standings" element={<StandingPage />} />
        <Route path="/team" element={<Team />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog-details" element={<Blogdetails />} />
        <Route path="/video" element={<Video />} />
        <Route path="/player-registration" element={<Registration />}/>





      </Routes>
    </Router>
    {/* <Footer/> */}
    </>

  );
}

export default App;
