import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Standing from "../Standing";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import breadcumb from '../bread (1).jpg'
export default function StandingPage() {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <>
     <Header/>

     
<div class="breadcrumb">
    <img src={breadcumb} alt="Breadcrumb Image" class="breadcrumb-image"/>
    <span class="breadcrumb-text"> Standings</span>
</div>


      <Standing />
      <Footer/>
    </>
  );
}
